<template>
  <div>
    <div class="page-title pageheading" style="height: 50px;">
      <h3 class="titlename">
        <i class="fas fa-user"></i><span class="ml-3">Prospective Client</span>
      </h3>

      <span class="float-right green-btn">
        <a class="btn btn-success mr-3" @click="Pcreate">
          <i class="material-icons">add</i> <span>Prospective Client</span></a
        >
      </span>
    </div>

    <div>
      <datatable
        :header="headers"
        :item="desserts"
        :showChangePass="showChangePass"
        :showAction="true"
        :showSearch="true"
        @editRecord="Pcreate()"
      ></datatable>
    </div>

    <b-modal
      size="xl"
      id="Pcreateuser"
      hide-footer
      centered
      no-close-on-backdrop
    >
      <template slot="modal-title">Add Client</template>
      <prospectiveClientWizard></prospectiveClientWizard>
    </b-modal>
  </div>
</template>
<script>
import datatable from "../../components/datatable.vue";
import prospectiveClientWizard from "./createProspectiveClient.vue";
export default {
  components: { datatable, prospectiveClientWizard },
  data() {
    return {
      search: "",
      clientid: "",
      showChangePass: true,
      desserts: [
        {
          callerfirstname: "Frozen ",
          callerlastname: " Yogurt",
          inquirydate: "",
          relationtoclient: 6.0,
          clientfirstname: 24,
          clientlastname: 4.0,
          referralsource: "1%",
          officename: "Frozen Yogurt",
          callerphone: "Frozen Yogurt",
          status: "Frozen Yogurt",
          territory: "Frozen Yogurt", 
        },
      ],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Caller First Name",
          align: "start",
          sortable: false,
          value: "callerfirstname",
          width: "150px",
        },
        { width: "150px", text: "Caller Last Name", value: "callerlastname" },
        { width: "150px", text: "Inquiry Date", value: "inquirydate" },
        {
          width: "150px",
          text: "Relation to Client",
          value: "relationtoclient",
        },
        { width: "150px", text: "Client First Name", value: "clientfirstname" },
        { width: "150px", text: "Client Last Name", value: "clientlastname" },
        { width: "150px", text: "Referral Source", value: "referralsource" },
        { width: "150px", text: "Office Name", value: "officename" },
        { width: "150px", text: "Caller Phone", value: "callerphone" },
        { width: "150px", text: "Status", value: "status" },
        { width: "150px", text: "Territory", value: "territory" },
        { width: "150px", text: "Action", value: "action" },
      ];
    },
  },
  methods: {
    Pcreate() {
      this.$bvModal.show("Pcreateuser");
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
  },
};
</script>
<style scoped>
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}
</style>
